import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ConfirmCodeModal from '@/components/Modal/EmailConfirmCodeModal.vue';
import { captureException } from '@/utils/handleErrors/handleErrors';
import useCabinetApi from '@/composables/use-cabinet-api';
import useModal from '@/composables/use-modal';

export default function useConfirmEmail() {
  const store = useStore();
  const currentEmail = computed(() => store.getters['cabinet/getUserEmail']);
  const modal = useModal();
  const emailLoading = ref<boolean>(false);
  const { apiClient } = useCabinetApi();

  const showConfirmModal = (email?: string) => {
    modal.show({
      component: ConfirmCodeModal,
      bind: {
        email,
      },
    });
  };

  const send = async (email: string | null = null) => {
    try {
      emailLoading.value = true;
      if (!email) {
        email = currentEmail.value;
      }

      store.commit('cabinet/setUserEmail', email);
      await apiClient.setEmail(email);

      showConfirmModal();
    } catch (error) {
      captureException({ error, place: 'useConfirmEmail' });
    } finally {
      emailLoading.value = false;
    }
  };

  return {
    send,
    emailLoading,
    showConfirmModal,
  };
}
