import { useStore } from 'vuex';
import useCabinetApi from '@/composables/use-cabinet-api';
import { captureException } from '@/utils/handleErrors/handleErrors';

export default function useOrderNotification() {
  const store = useStore();
  const { apiClient } = useCabinetApi();

  return async () => {
    try {
      const ordersHasUpdates = (await apiClient.getOrderNotifications()).ordersHasUpdates;
      store.commit('cabinet/setOrdersHasUpdates', ordersHasUpdates);
    } catch (error) {
      captureException({ place: 'useOrderNotification', error });
    }
  };
}
